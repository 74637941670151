import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

  // {
  //   id: 2,
  //   label: 'MENUITEMS.DASHBOARDS.TEXT',
  //   icon: 'bx-home-circle',
  //   link: '/dashboard',

  // },
  {
    id: 2,
    label: 'Customer',
    icon: 'bx bxs-user-detail',
    subItems: [
      {
        id: 21,
        label: 'Customer Listing ',
        link: '/customer/list',
        parentId: 2
      },
      {
        id: 22,
        label: 'Archived',
        link: '/customer/archived',
        parentId: 2
      },
      {
        id: 23,
        label: 'Corporate Users',
        link: 'corpcode/list',
        parentId: 2
      },
      // {
      //   id: 23,
      //   label: 'Add Customer',
      //   link: '/customer/add',
      //   parentId: 21
      // },
    ]
  },

  {
    id: 21,
    label: 'Billing Sheet',
    icon: 'bx bxs-receipt ',
    subItems: [
      {
        id: 22,
        label: 'List',
        link: '/billingsheet/list',
        parentId: 21
      },
      {
        id: 23,
        label: 'Items List',
        link: 'billingsheet/item/list',
        parentId: 21
      },
    ]
  },


  {
    id: 100,
    label: 'Project',
    icon: 'bx bx-briefcase-alt-2',
    subItems: [
      {
        id: 101,
        label: 'Project Listing ',
        link: '/project/list',
        parentId: 100
      },
      {
        id: 102,
        label: 'Archived',
        link: '/project/archived',
        parentId: 100
      }
    ]
  },
  {
    id: 101,
    label: 'Driver',
    icon: 'bx bx-male ',
    subItems: [
      {
        id: 101,
        label: 'Driver List ',
        link: '/driver/list',
        parentId: 101
      },
      // {
      //   id: 102,
      //   label: 'Add Driver',
      //   link: '/driver/add',
      //   parentId: 100
      // }
    ]
  },

  {
    id: 102,
    label: 'Truck',
    icon: 'bx bxs-car',
    subItems: [
      {
        id: 101,
        label: 'Truck List ',
        link: '/truck/list',
        parentId: 102
      },
      // {
      //   id: 102,
      //   label: 'Add Truck',
      //   link: '/truck/add',
      //   parentId: 100
      // }
    ]
  },
  {
    id: 300,
    label: 'Carrier',
    icon: 'bx bxs-truck',
    subItems: [
      {
        id: 101,
        label: 'Carrier List ',
        link: '/carrier/list',
        parentId: 300
      },
  ]
  },
  {
    id: 200,
    label: 'Warehouse',
    icon: 'bx bxs-store-alt ',
    subItems: [
      {
        id: 101,
        label: 'Warehouse List ',
        link: '/warehouse/list',
        parentId: 200
      },
      // {
      //   id: 102,
      //   label: 'Add Warehouse',
      //   link: '/warehouse/add',
      //   parentId: 200
      // }
    ]

  },
  {
    id: 201,
    label: 'Lot',
    icon: 'bx bxs-collection',
    subItems: [
      {
        id: 22,
        label: 'Lot Listing ',
        link: '/lot/list',
        parentId: 201
      },
      // {
      //   id: 22,
      //   label: 'Add Lot',
      //   link: '/lot/add',
      //   parentId: 21
      // },

    ]
  },
  {
    id: 1,
    label: 'Reports',
    icon: 'bxs-report',
    subItems: [
      {
        id: 13,
        label: 'Bill Of Lading',
        link: '/reports/bol',
        parentId: 1
      },
      {
        id: 14,
        label: 'Billing Sheet',
        link: '/reports/bsr',
        parentId: 1
      },
      {
        id: 15,
        label: 'Project Lot History',
        link: '/reports/plh',
        parentId: 1
      },
      {
        id: 16,
        label: 'Manifest',
        link: '/reports/manifest',
        parentId: 1
      },
      {
        id: 17,
        label: 'Billing invoice',
        link: '/reports/billinginvoice',
        parentId: 1
      },
      {
        id: 17,
        label: 'Invoice Summary Report',
        link: '/reports/invoicesummary',
        parentId: 1
      },

      {
        id: 18,
        label: 'Trucking Other Report',
        link: '/reports/truckingother',
        parentId: 1
      }

    ]
  },
  {
    id: 400,
    label: 'Shipper',
    icon: 'bx bx-envelope',
    subItems: [
      {
        id: 101,
        label: 'Shipper List ',
        link: '/shipper/list',
        parentId: 400
      }
    ]
  },
  {
    id: 401,
    label: 'Trucking / Crane / Other',
    icon: 'bx bxs-truck',
    subItems: [
      {
        id: 101,
        label: 'List',
        link: '/trucking/list',
        parentId: 401
      }
    ]
  },

];

