<div id="layout-wrapper">

  <div class="main-content">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer></app-footer>
</div>
