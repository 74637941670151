import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';

import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if (environment.defaultauth === 'firebase') {
        //     const currentUser = this.authenticationService.currentUserValue;
        //     if (currentUser && currentUser.token) {
        //         request = request.clone({
        //             setHeaders: {
        //                 Authorization: `Bearer ${currentUser.token}`
        //             }
        //         });
        //     }
        // } else {
            // add authorization header with jwt token if available
            const currentUser = this.authenticationService.currentUserValue;
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`
                    }
                });
            }
        // }



        return next.handle(request).pipe(
            catchError((error:any,event)=>{
                console.log(error,"error");

                if(error.name ==="TokenExpiredError"){
                    console.log(error,"error",)

                    this.authenticationService.logout()

                }else{
                    console.log("nothing cathced");

                }

                return throwError(error)
            })
        )
        // .catch( (error:any) => {
        //     if(error instanceof HttpErrorResponse && error.error.status ==403)
        //         this.authenticationService.logout()

        //     return Observable.throwError(error)
        // })
    }
}
