import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { User } from '../models/auth.models';
import { catchError, map, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

const baseUrl = environment.baseUrl;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient,private router:Router) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
      return this.http.post<any>(`${baseUrl}auth/login`, { email: email?.toLowerCase(), password })
          .pipe(map((response : any) => {
              console.log(response.data,"repomse ");
              // login successful if there's a jwt token in the response
              if (response.data.user && response.data.token) {

                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify(response.data));
                  this.currentUserSubject.next(response.data);
              }
              return response.data;
          }));
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.router.navigate(['/account/login']);
      this.currentUserSubject.next(null);
  }
}










// import { User } from '../models/auth.models';

// @Injectable({ providedIn: 'root' })
// export class AuthfakeauthenticationService {

// }
